@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap);
hr {
  border-color: hsla(0, 0%, 0%, 0.125);
}

* {
  box-sizing: border-box;
}

a {
  color: hsl(127, 55%, 43%);
  text-decoration: none;
}

html,
body {
  background: #1b1b1c;
  color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
}

.App.dark ::-webkit-scrollbar {
  width: 8px;
}

.App.dark ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px hsla(0, 0%, 0%, 0.66);
  -webkit-box-shadow: inset 0 0 6px hsla(0, 0%, 0%, 0.66);
  border-radius: 10px;
}

.App.dark ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px hsla(0, 0%, 0%, 0.66);
}

.stuck {
  position: sticky;
}

[role="progressbar"] {
  position: relative;
  padding: 0;
  width: 100px;
  height: 25px;
  list-style: none;
}
[role="progressbar"] li {
  display: block;
  position: absolute;
  right: 0;
  width: 3px;
  height: 25px;
  border-radius: 1px;
  transform-origin: 50% 100%;
  background-color: hsl(0, 0%, 50%);
  animation: bar linear 2.8s infinite;
  will-change: transform;
}
[role="progressbar"] li:nth-child(1) {
  transform: translateX(0) rotate(0deg);
  opacity: 0.2;
}
[role="progressbar"] li:nth-child(2) {
  transform: translateX(-15px) rotate(0deg);
  animation-delay: -0.4s;
}
[role="progressbar"] li:nth-child(3) {
  transform: translateX(-30px) rotate(0deg);
  animation-delay: -0.8s;
}
[role="progressbar"] li:nth-child(4) {
  transform: translateX(-45px) rotate(10deg);
  animation-delay: -1.2s;
}
[role="progressbar"] li:nth-child(5) {
  transform: translateX(-60px) rotate(40deg);
  animation-delay: -1.6s;
}
[role="progressbar"] li:nth-child(6) {
  transform: translateX(-75px) rotate(62deg);
  animation-delay: -2s;
}
[role="progressbar"] li:nth-child(7) {
  transform: translateX(-90px) rotate(72deg);
  animation-delay: -2.4s;
}

@keyframes bar {
  0% {
    transform: translateX(0) rotate(0deg);
    opacity: 0;
  }
  14.28% {
    transform: translateX(-15px) rotate(0deg);
    opacity: 1;
  }
  28.56% {
    transform: translateX(-30px) rotate(0deg);
    opacity: 1;
  }
  37.12% {
    transform: translateX(-39px) rotate(0deg);
    opacity: 1;
  }
  42.84% {
    transform: translateX(-45px) rotate(10deg);
    opacity: 1;
  }
  57.12% {
    transform: translateX(-60px) rotate(40deg);
    opacity: 1;
  }
  71.4% {
    transform: translateX(-75px) rotate(62deg);
    opacity: 1;
  }
  85.68% {
    transform: translateX(-90px) rotate(72deg);
    opacity: 1;
  }
  100% {
    transform: translateX(-105px) rotate(74deg);
    opacity: 0;
  }
}

/* html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  background-color: #1a6aaf;
}

a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
  -webkit-transition: border-color ease-out 0.15s;
  transition: border-color ease-out 0.15s;
}
a:focus,
a:hover {
  border-bottom-color: #fff;
}

p {
  margin-top: 40px;
  color: #fff;
  font-size: 14px;
  font-family: Avenir Next, Helvetica Neue, Helvetica, sans-serif;
} */

[role="progressbar"] {
  margin: 0 auto;
}

.Toolbar {
  max-width: 420px;
  min-width: 42px;
  font-size: larger;
}

.Toolbar .tab {
  width: 100%;
  /* min-width: 42px; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 0.5em 0;
  color: #fff9;
}

.Toolbar > * .detail {
  min-width: 420px;
  display: none;
}

.Toolbar > *:hover .detail {
  display: block;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.SignContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

